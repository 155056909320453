import {Component, computed, effect, Input} from '@angular/core';
import * as Sentry from '@sentry/browser';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from '@angular/material/snack-bar';
import {QuestionService} from "../../services/question.service";
import {UserPromptResponse} from "../../interfaces/user-prompt-response";
import {CaptureContext, ScopeContext} from "@sentry/types/types/scope";
import {ChatMessage} from "../../interfaces/chat_message";


@Component({
  selector: 'feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent {

  answerHasBeenRated: number[] = [] ;

  @Input() rating: { value: number } = { value: 0 }; // Default rating
  @Input() setRating: (newRating: number) => void = () => {}; // Default function
  @Input() messageIndex: number = 0; // Default index
  @Input() isGeneralFeedback : boolean = false // Default bool

  extendedFeedbackGiven: Array<number> = [];
  negativeRatings : Array<number> = [];
  showFeedbackAccordion : Array<number> = [];


  email: any;
  feedback: any;
  dropdown: any;
  currentEventId: string = ''
  lastServerRequestUuid = ''
  chatHistory = this.questionService.chatHistorySignal()
  lastChatIndex = this.chatHistory.length > 0 ? this.chatHistory.length -1 : 0;
  uuid = this.chatHistory[this.lastChatIndex].meta["server_request_uuid"];


  feedbackForm: FormGroup; // Define the form

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private questionService: QuestionService,
  ) {
    this.questionService.newQuestion.subscribe(() =>{
      this.resetFeedbackStatus()
    })
    this.feedbackForm = this.fb.group({
      email: ['', [Validators.email]], //optional
      dropdown: [''], // optional
      feedback: ['']// optional
    });

  }

  resetFeedbackStatus(){
    this.lastServerRequestUuid = '';
    this.currentEventId = '';
    this.email = '';
    this.feedback = '';
    this.dropdown = '';
  }

  hideFeedbackAccordion(){
    if (this.showFeedbackAccordion.includes(this.messageIndex)){
      const index = this.showFeedbackAccordion.indexOf(this.messageIndex);
      if (index > -1) {
         this.showFeedbackAccordion.splice(index, 1);
      }
    }
  }

  openSnackBar(message: string, action?: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

  callSetRating(newRating: number): void {
    this.setRating(newRating)
  }

  sendShortFeedback(rating: number) {
    console.log("isGeneralFeedback; ", this.isGeneralFeedback)
    if (!this.answerHasBeenRated.includes(this.messageIndex) || this.isGeneralFeedback){
      this.callSetRating(rating)
    if (rating == 0){
      this.negativeRatings.push(this.messageIndex)
    }
    this.showFeedbackAccordion.push((this.messageIndex))
      this.sendFeedback()
    }
    else{
      this.openSnackBar($localize`Für diese Antwort hast du bereits eine Rückmeldung abgegeben.`);
    }
  }

  sendFeedback() {

    try{
      const serverRequestUuid = this.uuid ? this.uuid : '';

      let modifiedChatHistory = this.modifyChatHistoryForSentry();

      let context : CaptureContext = {
          extra:
            {
              "rating": this.rating.value,
              "Chat History": modifiedChatHistory,
              "Rating about nth answer" : this.messageIndex,
              "server_request_uuid" : serverRequestUuid,
              "is general feedback" : this.isGeneralFeedback
              },
          tags: {
            "rating": this.rating.value,
            "generalFeedback": this.isGeneralFeedback
          }
      }
      const message = "User Rating"
      console.log("context ; ", context)

      this.currentEventId = Sentry.captureMessage(message, context)

      this.lastServerRequestUuid = serverRequestUuid


      if (!this.isGeneralFeedback){
        this.answerHasBeenRated.push(this.messageIndex);

        let popUpMessage = $localize`Vielen Dank für Deine Rückmeldung! Willst Du mir noch mehr Feedback geben?`
        this.openSnackBar(popUpMessage)
      }

    } catch (error){
      this.openSnackBar($localize`Ups, es ist etwas schief gelaufen. Bitte versuche es später noch einmal.`)
    }

  }


  sendExtendedFeedback() {

    this.email = this.feedbackForm.value.email;
    this.dropdown = this.feedbackForm.value.dropdown;
    this.feedback = this.feedbackForm.value.feedback;

    try{
      const serverRequestUuid = this.uuid ? this.uuid : '';

      let context: CaptureContext = {
        extra:
          {
            "eventID": serverRequestUuid,
            "rating": this.rating.value.toString(),
            "Chat History": this.modifyChatHistoryForSentry(),
            "feedback_category": this.dropdown,
            "comment": this.feedback,
            "email": this.email
          },
        tags: {
          "rating": this.rating.value,
          "feedback_category": this.dropdown,
        }
      }
      let sentiment = "Extended User Feedback";
      Sentry.captureMessage(sentiment, context);
      this.extendedFeedbackGiven.push(this.messageIndex)
      this.openSnackBar($localize`Vielen Dank für deine erweiterte Rückmeldung!`);
    }

    catch (error){
      this.openSnackBar($localize`Ups, es ist etwas schief gelaufen. Bitte versuche es später noch einmal.`);
    }

  }


  private modifyChatHistoryForSentry() {
    // meta-objects should be dictionaries, such that they are readable when sent to sentry for feedback

    let modifiedChatHistory: ChatMessage[] = []
    for (let chat of this.chatHistory) {
      let modifiedChat = JSON.parse(JSON.stringify(chat)); //deepcopy
      modifiedChat.meta = JSON.stringify(chat.meta)
      modifiedChatHistory.push(modifiedChat);
    }

    let size = new Blob([JSON.stringify(modifiedChatHistory)]).size
    if (size > 16000) {
      console.warn("Chat History size exceeds Sentry size limit for extra data item. ")
    }

    return modifiedChatHistory;
  }

}
