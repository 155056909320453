import { Component } from '@angular/core';
import {WhitelabelService} from "../../services/whitelabel.service";

@Component({
  selector: 'privacy-hint-hallucination-disclaimer',
  templateUrl: './privacy-hint-hallucination-disclaimer.component.html',
  styleUrls: ['./privacy-hint-hallucination-disclaimer.component.scss']
})
export class PrivacyHintHallucinationDisclaimerComponent {

  showGeneralFeedbackForm: boolean = false;


  isBiblioSH = this.whiteLabelService.isBiblioSH

  constructor(
    private whiteLabelService: WhitelabelService,
  ) {
  }

  extendGeneralFeedbackForm() {
    this.showGeneralFeedbackForm = !this.showGeneralFeedbackForm
  }

}
